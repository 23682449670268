#search-selection,
#search-form {
  //display: inline-block;
  //vertical-align: top;
}

.toggle-all:hover {
  text-decoration: underline;
  cursor: pointer;
}

#search-selection {
  float: left;
  padding-right: 42px;
  //line-height: 15.2pt;
  ///*max-width: 280px;*/
  //max-width: 50%;
}

@media (min-width: 768px) and (max-width: 991px) {
  #search-selection,
  #search-form {
    width: 50%;
  }
}

.search-selection-soorten {
  margin-bottom: 35px;
}

.search-selection-i-root {
  margin-bottom: 10px;
}

.search-selection-i-sublist {
  padding-left: 25px;
}

.hidden-checkbox {
  display: none;
}

.custom-textfield-autocomplete .custom-textfield {
  border-right-width: 0;
  width: 318px;
}

.custom-textfield-autocomplete-btn {
  background-image: url('../images/chosen-arrow.png');
  width: 32px;
  height: 34px;
  vertical-align: top;
  display: inline-block;
  padding: 0 !important;
  border: 1px solid #ccc !important;
  border-left-width: 0 !important;
}

.custom-select-chosen {
  color: #FFFFFF;
  height: 36px;
}

.btn-navigate {
  display: block;
}

#googlemap_address {
  height: 28px;
  font-size: 15px;
}

.google-maps-search-location {
  border: 1px solid #ccc;
  border-top-width: 0;
  padding: 7px;
  background-color: #f2f2f2;
  width: 533px;
}

@media (max-width: 880px) {
  .google-maps-search-location {
    width: 100% !important;
  }
}

.btn-submit-maps-location {
  width: 28px !important;
  height: 28px !important;
  padding: 0 !important;
  display: inline-block;
  vertical-align: top;
  font-size: 18px !important;
  padding: 3px 0 0 0 !important;
  text-align: center;
}

.btn-empty {
  display: none;
}

.js-enabled .btn-empty {
  display: inline-block;
  float: right;
  background-color: #ccc;
}

#map-canvas {
  border: 1px solid #ccc;
}

.btn-search {
  background-image: url('../images/icon-magnifying-glass.png');
  background-repeat: no-repeat;
  background-position: right center !important;
  padding-right: 49px !important;
}

.search-form-field {
  width: auto;
  display: inline-block;
}
